import React from 'react';

import { Media, Panel } from '../../../models/Strapi';
import Column from '../../../molecules/StrapiComponent/Column/Column';
import styles from './ColumnsModule.css';
import { UrlService } from '../../../services/UrlService';

type Component = {
    id: number;
    panels: Panel[];
    moduleScreenId?: string;
};

type ColumnsModuleProps = {
    title: string;
    component: Component[];
    background: Media;
    backgroundFallback: string;
};

const ColumnsModule = React.memo((props: ColumnsModuleProps) => {
    const {
        title,
        component: [columnsComponent],
    } = props;

    const getBackground = ({ background, backgroundFallback }: ColumnsModuleProps) => {
        const result = {} as React.CSSProperties;
        if (background) {
            result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
        }
        result.backgroundColor = backgroundFallback;
        return result;
    };

    const isTwoColumns = columnsComponent.panels.length == 2;
    const { moduleScreenId } = columnsComponent || {};
    return (
        <div className={styles.content} style={getBackground(props)}>
            <section className={styles.container}>
                {!isTwoColumns && <h2 className={styles.mainTitle}>{title}</h2>}
                <div className={styles.columnsList}>
                    {columnsComponent.panels.map((panel) => {
                        return (
                            <Column
                                key={panel.id}
                                panel={panel}
                                moduleScreenId={moduleScreenId}
                                isTwoColumns={isTwoColumns}
                            />
                        );
                    })}
                </div>
            </section>
        </div>
    );
});

export default ColumnsModule;
